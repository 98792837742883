import produce from 'immer';
import { Model, ModelInformation, ModelInformationMap, PartInformation, useModelStore } from '../stores';
import { MachineController } from './MachineController';
import { ProjectController } from './ProjectController';
import { StepController, ModelController } from '.';
import { ImperativeModelController } from './ImperativeModelController';
import _ from 'lodash';

export namespace ModelVisibilityController {
  export const setPartVisible = (gltfIndex: number, visible: boolean, checkDeselect = false) => {
    if (!visible && checkDeselect) {
      MachineController.deselectParts(gltfIndex);
    }

    let isExcluded = false;
    useModelStore.setState(
      produce<Model>((state: Model) => {
        const partInformation = state.modelInformationMap.get(gltfIndex) as PartInformation;
        if (partInformation) {
          partInformation.visible = visible;
          state.modelInformationMap.set(gltfIndex, partInformation);
          isExcluded = partInformation.excluded;
        }
        ImperativeModelController.setTransparency(
          gltfIndex,
          !visible ? 0 : partInformation && partInformation.transparent ? 0.5 : 1
        );
      })
    );

    if (!isExcluded) ImperativeModelController.setVisible(gltfIndex, visible);
  };

  export const setAllPartsVisible = () => {
    useModelStore.setState(
      produce<Model>((state: Model) => {
        state.modelInformationMap.forEach((modelInformation, gltfIndex) => {
          if (_.has(modelInformation, 'visible')) {
            const partInformation = modelInformation as PartInformation;
            partInformation.visible = true;
            state.modelInformationMap.set(gltfIndex, partInformation);
          }
        });
      })
    );
  };

  export const setAssemblyVisible = (gltfIndex: number, visible: boolean, checkDeselect = false) => {
    const assembly = ProjectController.getAssemblyByGltfIndex(gltfIndex);
    if (assembly) {
      assembly.assemblies.forEach((assemblyIndex) => {
        const assembly = ProjectController.getAssemblyByProjectIndex(assemblyIndex);
        setAssemblyVisible(assembly.gltfIndex, visible, checkDeselect);
      });

      assembly.parts.forEach((partIndex) =>
        setPartVisible(ProjectController.getPartByProjectIndex(partIndex).gltfIndex, visible, checkDeselect)
      );
    }
  };

  // Assembly is visible as long as it contains a part that is visible
  export const isAssemblyVisible = (gltfIndex: number): boolean => {
    const allPartGLTFIndices = ProjectController.getPartsGltfIndicesOfAssemblyByGltfIndex(gltfIndex, true);

    // Filter out parts that are excluded
    const notExcludedPartGLTFIndices = allPartGLTFIndices.filter((partGltfIndex) => {
      const partInformation = useModelStore.getState().modelInformationMap.get(partGltfIndex) as PartInformation;
      return !partInformation.excluded;
    });

    return notExcludedPartGLTFIndices.some((partGltfIndex) => isPartVisible(partGltfIndex));
  };

  // Assembly is transparent if all parts it contains are transparent
  export const isAssemblyTransparent = (gltfIndex: number): boolean => {
    const allPartGLTFIndices = ProjectController.getPartsGltfIndicesOfAssemblyByGltfIndex(gltfIndex, true);

    // Filter out parts that are excluded
    const notExcludedPartGLTFIndices = allPartGLTFIndices.filter((partGltfIndex) => {
      const partInformation = useModelStore.getState().modelInformationMap.get(partGltfIndex) as PartInformation;
      return !partInformation.excluded;
    });

    return notExcludedPartGLTFIndices.every((partGltfIndex) => isPartTransparent(partGltfIndex));
  };

  export const setAssemblyTransparent = (gltfIndex: number, transparent: boolean): void => {
    const assembly = ProjectController.getAssemblyByGltfIndex(gltfIndex);
    if (assembly) {
      assembly.assemblies.forEach((projectIndex) => {
        const gltfIndex = ProjectController.getAssemblyByProjectIndex(projectIndex).gltfIndex;
        setAssemblyTransparent(gltfIndex, transparent);
      });
      assembly.parts.forEach((part) =>
        setPartTransparent(ProjectController.getPartByProjectIndex(part).gltfIndex, transparent)
      );
    }
  };

  export const isPartVisible = (gltfIndex: number): boolean => {
    const partInformation = useModelStore.getState().modelInformationMap.get(gltfIndex);
    return partInformation !== undefined && (partInformation as PartInformation).visible;
  };

  export const isPartTransparent = (gltfIndex: number): boolean => {
    const partInformation = useModelStore.getState().modelInformationMap.get(gltfIndex);
    return partInformation !== undefined && (partInformation as PartInformation).transparent;
  };

  export const setPartsInSequenceTransparent = () => {
    StepController.getAllStepsFlat().forEach((step) => {
      step.data.parts.forEach((part) => {
        ImperativeModelController.setTransparency(part.partGltfIndex, 0);
      });
    });
  };

  export const setPartTransparent = (gltfIndex: number, transparent: boolean): void => {
    useModelStore.setState(
      produce<Model>((state: Model) => {
        const partInformation = state.modelInformationMap.get(gltfIndex) as PartInformation;
        if (partInformation) {
          partInformation.transparent = transparent;
          state.modelInformationMap.set(gltfIndex, partInformation);
        }
      })
    );
    ImperativeModelController.setTransparent(gltfIndex, transparent);
    const parentGltfIndex = ProjectController.getParent(gltfIndex);
    if (parentGltfIndex !== undefined) {
      applyAssemblyTransparency(parentGltfIndex);
    }
  };

  export const setPartTransparency = (gltfIndex: number, transparency: number): void => {
    useModelStore.setState(
      produce<Model>((state: Model) => {
        const partInformation = state.modelInformationMap.get(gltfIndex) as PartInformation;
        if (partInformation) {
          partInformation.transparent = transparency < 1;
          state.modelInformationMap.set(gltfIndex, partInformation);
        }
      })
    );
    ImperativeModelController.setTransparency(gltfIndex, transparency);
    const parentGltfIndex = ProjectController.getParent(gltfIndex);
    if (parentGltfIndex !== undefined) {
      applyAssemblyTransparency(parentGltfIndex);
    }
  };

  export const setTransparencyOffCached = (): void => {
    useModelStore.setState(
      produce<Model>((state: Model) => {
        state.modelInformationMapCache = Array.from(state.modelInformationMap.entries()).reduce<ModelInformationMap>(
          (cache, entry) => {
            cache.set(entry[0], _.cloneDeep(entry[1]));
            return cache;
          },
          new Map<number, ModelInformation>()
        );
        state.modelInformationMap.forEach((modelInformation, gltfIndex) => {
          if (ModelController.isPartInformation(modelInformation)) {
            modelInformation.transparent = false;
            ImperativeModelController.setTransparent(gltfIndex, false);
          }
        });
      })
    );
  };

  export const restoreTransparencyFromCache = (): void => {
    useModelStore.setState(
      produce<Model>((state: Model) => {
        state.modelInformationMap.forEach((modelInformation, gltfIndex) => {
          if (ModelController.isPartInformation(modelInformation)) {
            const transparent =
              (state.modelInformationMapCache.get(gltfIndex) as PartInformation)?.transparent === true;
            modelInformation.transparent = transparent;
            ImperativeModelController.setTransparent(gltfIndex, transparent);
          }
        });
        state.modelInformationMapCache = {} as ModelInformationMap;
      })
    );
  };

  const applyAssemblyTransparency = (gltfIndex: number) => {
    useModelStore.setState(
      produce<Model>((state: Model) => {
        const partInformation = state.modelInformationMap.get(gltfIndex) as PartInformation;
        if (partInformation) {
          partInformation.transparent = isAssemblyTransparent(gltfIndex);
          state.modelInformationMap.set(gltfIndex, partInformation);
        }
      })
    );
  };
}
