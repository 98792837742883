import {
  useStepRename,
  useStepSetAnimationSpeed,
  useStepSetMTM,
  useStepSetPlayWithAbove,
  useStepSetType,
} from '@assemblio/frontend/data-access';
import { StepController } from '@assemblio/frontend/stores';
import { notifications } from '@mantine/notifications';

export const useStepModification = () => {
  const stepSetTypeMutation = useStepSetType();
  const setPlayWithAboveMutation = useStepSetPlayWithAbove();
  const stepRenameMutation = useStepRename();
  const stepSetMTMMutation = useStepSetMTM();
  const stepSetAnimationSpeed = useStepSetAnimationSpeed();

  const handleNameChange = (id: string, oldName: string, newName: string) => {
    StepController.renameStep(id, newName);
    stepRenameMutation.mutate(
      { id, data: { name: newName } },
      {
        onError: () => {
          StepController.renameStep(id, oldName);
        },
      }
    );
  };

  const handleChangeType = (stepId: string, currentType: string) => {
    const newType = currentType === 'alignment' ? 'assembly' : 'alignment';
    StepController.setType(stepId, newType);
    stepSetTypeMutation.mutate(
      {
        id: stepId,
        data: { type: newType },
      },
      {
        onError: () => {
          StepController.setType(
            stepId,
            newType === 'alignment' ? 'assembly' : 'alignment'
          );
        },
      }
    );
  };

  const handleChangePlayWithAbove = (
    event: React.ChangeEvent<HTMLInputElement>,
    stepId: string
  ) => {
    const wasSet = StepController.setPlayWithAbove(
      stepId,
      event.target.checked
    );
    if (wasSet) {
      setPlayWithAboveMutation.mutate(
        { id: stepId, data: event.target.checked },
        {
          onError: () => {
            StepController.setPlayWithAbove(stepId, !event.target.checked);
          },
        }
      );
    } else {
      notifications.show({
        id: `set-play-with-above`,
        message: 'Play With Above cannot be set on this Step',
        color: 'red',
      });
    }
  };

  const handleMTMChange = (id: string, oldValue: number, newValue: number) => {
    const roundedValue = parseFloat(newValue.toFixed(1));
    StepController.setMTM(id, roundedValue);
    stepSetMTMMutation.mutate(
      { id, data: { mtm: roundedValue } },
      {
        onError: () => {
          StepController.setMTM(id, oldValue);
        },
      }
    );
  };

  const handleChangeAnimationSpeed = (
    id: string,
    oldValue: number,
    newValue: number
  ) => {
    const roundedValue = parseFloat(newValue.toFixed(1));
    StepController.setAnimationSpeed(id, roundedValue);
    stepSetAnimationSpeed.mutate(
      { id, data: { animationSpeed: roundedValue } },
      {
        onError: () => {
          StepController.setAnimationSpeed(id, oldValue);
        },
      }
    );
  };

  return {
    handleNameChange,
    handleChangeType,
    handleChangePlayWithAbove,
    handleMTMChange,
    handleChangeAnimationSpeed,
  };
};
