import { useDeleteJob, useRetryJob } from '@assemblio/frontend/data-access';
import { useEventStore } from '@assemblio/frontend/stores';
import { JobState } from '@assemblio/type/job';
import {
  ActionIcon,
  Badge,
  Box,
  Flex,
  MantineSize,
  Menu,
  Progress,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  Icon3dCubeSphere,
  IconDotsVertical,
  IconFileSpreadsheet,
  IconRefresh,
  IconReportAnalytics,
  IconRocket,
  IconTrash,
  IconVideo,
} from '@tabler/icons-react';
import { useEffect } from 'react';
import { useConfirmationDialog } from '@assemblio/frontend/components';
import classes from './Cards.module.scss';
import { CardMenuItem } from './ExplorerCard/CardMenuItem';
import { JobDto } from '@assemblio/shared/dtos';
import { JobType } from '@assemblio/type/job';

interface JobCardProps {
  job: JobDto;
  instructionId: string;
  onComplete?: (jobId: string) => void;
}

export const JobCard = ({ job, instructionId, onComplete }: JobCardProps) => {
  const { title } = getJobDisplays(job);
  const theme = useMantineTheme();
  const progress = useEventStore((state) => state.jobProgress.get(job.id));
  const status = useEventStore((state) => state.jobStates.get(job.id));

  useEffect(() => {
    if (status === 'completed' && onComplete) {
      onComplete(job.id);
    }
  }, [status, onComplete, job.id]);
  /*
  const [progress, setProgress] = useState(getCurrentProgress(job));
  const [status, setStatus] = useState(getStatusMessage(job.status));

  useEffect(() => {
    const unsubscribeProgress = useEventStore.subscribe(
      (state) => state.jobProgress.get(job.id),
      (fraction) => {
        if (_.isNumber(fraction)) {
          setProgress(fraction * 100);
        }
      }
    );
    const unsubscribeState = useEventStore.subscribe(
      (state) => state.jobStates.get(job.id),
      (state) => {
        if (state === 'completed') {
          onComplete && onComplete(job.id);
        }
        if (state) {
          setStatus(getStatusMessage(state));
        }
      }
    );
    return () => {
      unsubscribeProgress();
      unsubscribeState();
    };
  }, []);
*/
  const deleteJobMutation = useDeleteJob();
  const retryJobMutation = useRetryJob();

  const handleDelete = () => {
    deleteJobMutation.mutate({ instructionId, jobId: job.id });
  };

  const handleRetry = () => {
    retryJobMutation.mutate({ instructionId, jobId: job.id });
  };

  const { openDialog, RenderConfirmationDialog } = useConfirmationDialog(
    'Are you sure you want to delete this job?',
    handleDelete,
  );

  const jobTypeToString = (JobType: string) => {
    switch (JobType) {
      case 'video':
        return 'Video Export';
      case 'gltf':
        return 'Product Conversion';
      case 'pdf':
        return 'PDF Export';
      case 'docx':
        return 'Word Export';
      case 'analysis':
        return 'Product Analysis';
      case 'export-video':
        return 'Video Export';
      default:
        return 'Job';
    }
  };

  return (
    <>
      <Flex px={'2.5%'} h={'70px'} mb={10} align={'center'} className={classes.listCard} w={'100%'}>
        <Flex
          direction={'column'}
          mb={5}
          h={'100%'}
          w={'60%'}
          justify={'center'}
          gap={'xs'}
          style={{ marginRight: 'auto' }}
        >
          <Tooltip openDelay={500} label={title}>
            <Text w={'90%'} truncate>
              {jobTypeToString(job.jobType)}
            </Text>
          </Tooltip>

          <Box h={'1rem'}>
            <Progress.Root size={'xl'} w={'80%'} color={job.status === 'failed' ? 'red' : 'green'}>
              <Box className={classes.progressbar__label}>
                <Text>{job.status}</Text>
              </Box>

              <Progress.Section
                value={getCurrentProgress(progress, job)}
                animated={job.jobType === JobType.GLTF}
              ></Progress.Section>
            </Progress.Root>
          </Box>
        </Flex>

        {job.status === 'completed' && <JobCardBadges type={job.jobType} />}

        {job.status === 'failed' && (
          <Menu trigger={'hover'}>
            <Menu.Target>
              <ActionIcon>
                <IconDotsVertical />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <CardMenuItem Icon={IconRefresh} label={'Retry'} onClick={handleRetry} />
              <CardMenuItem
                Icon={IconTrash}
                label={'Delete'}
                color={theme.other.color.brand.pink}
                onClick={openDialog}
              />
            </Menu.Dropdown>
          </Menu>
        )}
      </Flex>
      {RenderConfirmationDialog()}
    </>
  );
};

interface JobCardBadgesProps {
  type: string;
}

const JobCardBadges = ({ type }: JobCardBadgesProps) => {
  //Using custom breakpoint here to avoid unwanted behavior the default breakpoints would not cover
  const xxlScreen = useMediaQuery(`(min-width: 2000px)`);
  const iconSize: MantineSize = xxlScreen ? 'md' : 'sm';
  return (
    <Flex h={'90%'} justify={'end'} align={'center'} gap={'xs'} mr={'md'}>
      {type && <Badge size={iconSize}>{type}</Badge>}
    </Flex>
  );
};

const getCurrentProgress = (progress: number | undefined, job: JobDto) => {
  if (job.jobType === JobType.GLTF) {
    return 100;
  }
  if (job.status === 'completed') {
    return 100;
  }
  if (progress) {
    return progress * 100;
  } else {
    return +job.progress * 100.0;
  }
};

const getStatusMessage = (state: JobState) => {
  if (state === 'completed') {
    return 'Your job has completed successfully.';
  }
  if (state === 'initialized') {
    return 'Your job has been initialized and is waiting for a free processor.';
  }
  if (state === 'running') {
    return 'Your job is running and should complete shortly.';
  }
  if (state === 'failed') {
    return 'Your job has failed. Please restart or delete it.';
  }
  return '';
};

const getJobDisplays = (job: JobDto): { icon: JSX.Element; title: string } => {
  let icon: JSX.Element;
  let title: string;
  switch (job.jobType) {
    case JobType.EXPORT_VIDEO:
      icon = <IconVideo />;
      title = 'Video Export';
      break;
    case JobType.GLTF:
      icon = <Icon3dCubeSphere />;
      title = 'Product Conversion';
      break;
    case JobType.EXPORT_PDF:
      icon = <IconFileSpreadsheet />;
      title = 'PDF Export';
      break;
    case JobType.ANALYSIS_COMPARISON:
      icon = <IconReportAnalytics />;
      title = 'Product Analysis';
      break;
    default:
      icon = <IconRocket />;
      title = 'Job';
  }
  return { icon, title };
};
