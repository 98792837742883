import {
  AnnotationArrowDto,
  AnnotationDtoWithId,
  AnnotationEllipseDto,
  AnnotationImageDto,
  AnnotationRectDto,
} from '@assemblio/shared/next-types';
import { AnnotationType, VerticalAlignment } from '@assemblio/type/annotation';
import { v4 as uuidv4 } from 'uuid';

type AnnotationCreationFunction = () => AnnotationDtoWithId | undefined;

const getPositionData = () => ({
  x: -0.1,
  y: -0.1,
});

const getSizeData = () => ({
  size: {
    width: 0.2,
    height: 0.2,
  },
});

const getShapeCommonData = () => ({
  color: '#fff',
  background: '#fd7e14',
  lineWidth: 0,
  lineColor: 'rgba(1,1,1,1)',
  verticalTextAlignment: 'top' as VerticalAlignment,
});

const createArrowAnnotation: AnnotationCreationFunction = () => {
  const positionData = getPositionData();

  return new AnnotationArrowDto(uuidv4(), {
    text: '',
    tx: 0.1,
    ty: 0,
    markerHead: 'arrow',
    markerTail: 'none',
    lineWidth: 0.02,
    color: 'black',
    ...positionData,
    x: -0.1,
    y: 0,
    anchorHead: {
      type: 'relative',
      position: null,
      annotationId: null,
    },
    anchorTail: {
      type: 'relative',
      position: null,
      annotationId: null,
    },
  });
};

const createRectAnnotation: AnnotationCreationFunction = () => {
  const positionData = getPositionData();
  const sizeData = getSizeData();
  const shapeCommonData = getShapeCommonData();

  return new AnnotationRectDto(uuidv4(), {
    text: '',
    ...positionData,
    ...sizeData,
    ...shapeCommonData,
  });
};

const createEllipseAnnotation: AnnotationCreationFunction = () => {
  const positionData = getPositionData();
  const sizeData = getSizeData();
  const shapeCommonData = getShapeCommonData();

  return new AnnotationEllipseDto(uuidv4(), {
    text: '',
    ...positionData,
    ...sizeData,
    ...shapeCommonData,
  });
};

const createImageAnnotation: AnnotationCreationFunction = () => {
  const positionData = getPositionData();
  const sizeData = getSizeData();

  return new AnnotationImageDto(uuidv4(), {
    ...positionData,
    ...sizeData,
  });
};

export const annotationCreators: Record<
  AnnotationType,
  AnnotationCreationFunction | null
> = {
  arrow: createArrowAnnotation,
  rect: createRectAnnotation,
  ellipse: createEllipseAnnotation,
  image: createImageAnnotation,
  icon: null, // As it's not creating any annotation here
  unknown: null,
};
