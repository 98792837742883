import { useCanvasStore, useModelStore } from '@assemblio/frontend/stores';
import { useViewportSize } from '@mantine/hooks';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  Camera,
  Event,
  Object3D,
  OrthographicCamera,
  Raycaster,
  Vector2,
  Vector3,
} from 'three';
import { cndcToNdc } from '../CoordinateSystems';

interface CameraObserverParams {
  onChange: (camera: Camera) => void;
}

export const useCameraObserver = ({ onChange }: CameraObserverParams) => {
  const cameraControls = useCanvasStore((state) => state.cameraControls);
  const camera = useCanvasStore((state) => state.camera);
  const { height, width } = useViewportSize();

  useEffect(() => {
    const onCameraChange = () => {
      if (cameraControls && camera) {
        _.defer(() => onChange(camera));
      }
    };
    if (cameraControls && camera) {
      cameraControls.addEventListener('update', onCameraChange);
      camera.addEventListener('change', onCameraChange);
      return () => {
        cameraControls.removeEventListener('update', onCameraChange);
        camera.removeEventListener('change', onCameraChange);
      };
    }
    return _.noop;
  }, [cameraControls, camera, onChange]);

  useEffect(() => {
    if (camera) {
      onChange(camera);
      _.defer(() => onChange(camera));
    }
  }, [height, width, camera, onChange]);
};

export const useScreenPosition = (
  position: Vector3 | null,
  viewportRect: {
    left: number;
    right: number;
    width: number;
    height: number;
    top: number;
    bottom: number;
  },
  onChange: (screen: Vector3, factors: Vector3, original: Vector3) => void
): void => {
  const [initialized, setInitialized] = useState(false);
  const onCameraChange = (camera: Camera) => {
    if (position && camera) {
      const screenFactors = position.clone().project(camera);

      const ndcScreenFactors = cndcToNdc({
        x: screenFactors.x,
        y: -screenFactors.y,
      });
      onChange(
        new Vector3(
          ndcScreenFactors.x * viewportRect.width + viewportRect.left,
          ndcScreenFactors.y * viewportRect.height + viewportRect.top,
          0
        ),
        screenFactors.clone(),
        position.clone()
      );
    }
  };
  useCameraObserver({
    onChange: onCameraChange,
  });
  if (!initialized) {
    const camera = useCanvasStore.getState().camera;
    if (camera) {
      onCameraChange(camera);
      setInitialized(true);
    }
  }
};

export const useCamera = () => {
  const camera = useCanvasStore((state) => state.camera);
  return camera;
};

export const useCameraCast = () => {
  const raycaster = useRef(new Raycaster());
  const camera = useCanvasStore((state) => state.camera);
  const ortho = camera as OrthographicCamera;
  ortho && ortho.updateProjectionMatrix();
  const model = useModelStore((state) => state.model);
  // I love closures. <3
  return (coords: {
    x: number;
    y: number;
  }): { position: Vector3; object: Object3D<Event> } | undefined => {
    if (camera && model) {
      raycaster.current.setFromCamera(new Vector2(coords.x, -coords.y), ortho);
      const intersections = raycaster.current.intersectObjects(
        model.scene.children
      );
      if (intersections.length) {
        const target = intersections.find(
          (intersection) => intersection.object.visible
        );
        if (target) {
          return { position: target.point, object: target.object };
        }
      }
    }
    return undefined;
  };
};
