import { InstructionDto, InstructionStateDto, InstructionTreeDto, SequenceExport } from '@assemblio/shared/next-types';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { axiosHandler } from '../utils/axios-utils';
import {
  ChangeInstructionOwner,
  ChangeInstructionState,
  InstructionSequenceQuery,
  ProductSetFavorite,
  RenameInstruction,
  SetInstructionEditorSettings,
} from './types/instruction.request.types';
import { QueryClient } from '@tanstack/react-query';
import {
  CreateRevisionDto,
  InstructionRevisionDto,
  ProductContentDto,
  ProductOverviewDto,
  ProductQueryDto,
} from '@assemblio/shared/dtos';
import { InstructionState } from '@assemblio/type/instruction';

export const fetchInstructionParts = async (id: string | undefined): Promise<InstructionTreeDto> => {
  return typeof id === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler('get', `/parts/instruction/${id}/tree`);
};

export const downloadGLTF = async (fileId: string | undefined): Promise<GLTF> => {
  return typeof fileId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler<ArrayBuffer>('get', `/instructions/files/${fileId}/gltf`, {
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'application/octet-stream' },
      }).then((data) => {
        return new Promise((resolve, reject) => {
          const loader = new GLTFLoader();
          loader.parse(
            data,
            '',
            (gltf: GLTF) => resolve(gltf),
            (event) => reject(event.error)
          );
        });
      });
};

export const fetchStepGroups = async (query: InstructionSequenceQuery): Promise<any[]> => {
  return typeof query.sequenceId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler('get', `/instructions/sequences/${query.sequenceId}/step-groups`, {
        params: { reverse_order: query.reverseOrder },
      });
};

export const fetchStepGroupsOfVersion = async (query: {
  instructionId: string | undefined;
  version: number | undefined;
}): Promise<any[]> => {
  return typeof query.instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler(
        'get',
        `/instructions/${query.instructionId}/version/${query.version}/step-groups?reverse_order=false`
      );
};

export const fetchInstruction = async (instructionId: string | undefined): Promise<InstructionDto> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler('get', `/instructions/${instructionId}`);
};

export const fetchProductContent = async (productId: string | undefined): Promise<ProductContentDto> => {
  return typeof productId === 'undefined'
    ? Promise.reject(new Error('Invalid id'))
    : axiosHandler('get', `/v1/products/${productId}`);
};

export const saveInstruction = async (instructionId: string | undefined): Promise<InstructionDto[]> => {
  return axiosHandler('post', `/instructions/${instructionId}/save`);
};

export const createRevisionRequest = async (data: CreateRevisionDto): Promise<InstructionDto[]> => {
  return axiosHandler('post', `/revisions`, { data });
};

export const fetchInstructionRevisions = async (
  instructionId: string | undefined
): Promise<InstructionRevisionDto[]> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `/instructions/${instructionId}/revisions`);
};

export const fetchInstructionVersions = async (instructionId: string | undefined): Promise<any[]> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `/instructions/${instructionId}/versions`);
};

export const fetchSequencesForExport = async (instructionId: string | undefined): Promise<SequenceExport[]> => {
  return typeof instructionId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `/instructions/${instructionId}/sequences/export`);
};

export const setInstructionEditorSettings = ({ id, data }: SetInstructionEditorSettings) => {
  return axiosHandler('put', `/instructions/${id}/settings`, {
    data,
  });
};

export const renameInstruction = ({ id, data }: RenameInstruction) => {
  return axiosHandler('patch', `/instructions/${id}/rename`, {
    data,
  });
};

export const changeOwnerOfInstruction = (data: ChangeInstructionOwner) => {
  return axiosHandler('put', `/instructions/${data.instructionId}/owner/${data.newOwnerId}`);
};

export const changeStateOfInstruction = (data: ChangeInstructionState) => {
  return axiosHandler('patch', `/instructions/${data.instructionId}/state`, {
    data,
  });
};

export const fetchInstructionState = async (
  instructionId: string,
  projectId: string,
  queryClient: QueryClient
): Promise<InstructionStateDto> => {
  return axiosHandler<InstructionStateDto>('get', `/instructions/${instructionId}/state`).then((result) => {
    if (result.state !== InstructionState.INITIALIZING) {
      queryClient.invalidateQueries(['project', projectId]);
    }
    return result;
  });
};

export const deleteInstruction = async (instructionId: string) => {
  return axiosHandler('delete', `/instructions/${instructionId}`);
};

export const setInstructionFavorite = async (data: ProductSetFavorite): Promise<void> => {
  return typeof data.productId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler(data.action === 'add' ? 'patch' : 'delete', `v1/products/${data.productId}/favourite`);
};

export const fetchFavoriteProducts = async (data?: ProductQueryDto): Promise<ProductOverviewDto[]> => {
  return axiosHandler('get', `v1/products/favourites`, { params: data });
};
