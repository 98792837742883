import {
  AnnotationController,
  useAnnotationStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { SizeableAnnotation } from '@assemblio/shared/next-types';
import { Portal } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Rnd } from 'react-rnd';
import {
  cndcToNdc,
  documentSizeToNds,
  documentToNdc,
  ndcToCndc,
  ndcToDocument,
  ndsToDocumentSize,
} from '../CoordinateSystems';
import { useUpdateAnnotation } from '@assemblio/frontend/data-access';
import { usePlaybackState } from '@assemblio/frontend/hooks';

interface Props {
  annotationId: string;
  annotation: SizeableAnnotation;
  toolbar: ReactNode;
  initialMode: 'editing' | 'display';
  lockAspectRatio?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export interface ResizableInterface {
  setSize: (
    width?: number,
    height?: number,
    options?: {
      grow?: boolean;
      shrink?: boolean;
    }
  ) => void;
  setSizeParameters: (width: number, height: number) => void;
}

export const ResizableAnnotation = forwardRef<
  ResizableInterface,
  PropsWithChildren<Props>
>(
  (
    {
      annotationId,
      annotation,
      toolbar,
      children,
      lockAspectRatio,
      onClick,
    }: PropsWithChildren<Props>,
    ref
  ) => {
    const {
      ref: containerRef,
      width,
      height,
    } = useElementSize<HTMLDivElement>();

    const view = useUIStore((state) => state.view);
    const isAnimating = useUIStore((state) => state.isAnimating);
    const disabled = view === 'viewer' || isAnimating;
    const [dimensions, setDimensions] = useState({
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    });
    const [initialized, setInitialized] = useState(false);

    const playbackState = usePlaybackState();

    const updateAnnotationMutation = useUpdateAnnotation();

    const { visible, mode, highlight } = useAnnotationStore((state) => {
      const annotationState = state.annotationMap.get(annotationId);
      return (
        annotationState || { visible: true, mode: 'editing', highlight: false }
      );
    });

    const persistAnnotation = (meta: Partial<SizeableAnnotation>) => {
      AnnotationController.updateAnnotation(annotationId, meta);
      mode === 'editing' &&
        updateAnnotationMutation.mutate({ id: annotationId, meta });
    };

    useEffect(() => {
      if (containerRef.current) {
        const { x: width, y: height } = ndsToDocumentSize(
          {
            x: annotation.size.width,
            y: annotation.size.height,
          },
          containerRef.current || undefined
        );
        const { x: left, y: top } = ndcToDocument(
          cndcToNdc({ x: annotation.x, y: annotation.y }),
          containerRef.current || undefined
        );
        setDimensions({ width, height, top, left });
        setInitialized(true);
      }
    }, [containerRef, annotation, width, height]);

    const setSizeParameters = (width: number, height: number) => {
      setDimensions({
        ...dimensions,
        width,
        height,
      });
    };

    const setSize = (
      w?: number,
      h?: number,
      options?: {
        grow?: boolean;
        shrink?: boolean;
      }
    ) => {
      if (initialized) {
        let height = annotation.size.height;
        let width = annotation.size.width;
        const initialHeight = height;
        const initialWidth = width;
        if (w !== undefined) {
          const ndcW = documentSizeToNds(
            { x: w, y: 0 },
            containerRef.current || undefined
          ).y;
          if (
            (options && options.grow && ndcW > width) ||
            (options && options.shrink && ndcW < width)
          ) {
            width = ndcW;
            setDimensions({
              width: w ? w : dimensions.width,
              height: dimensions.height,
              top: dimensions.top,
              left: dimensions.left,
            });
          }
        }
        if (h !== undefined) {
          const ndcH = documentSizeToNds(
            { x: 0, y: h },
            containerRef.current || undefined
          ).y;
          if (
            (options && options.grow && ndcH > height) ||
            (options && options.shrink && ndcH < height)
          ) {
            height = ndcH;
            setDimensions({
              width: dimensions.width,
              height: h ? h : dimensions.height,
              top: dimensions.top,
              left: dimensions.left,
            });
          }
        }
        if (width !== initialWidth || height !== initialHeight) {
          persistAnnotation({
            size: {
              width,
              height,
            },
          });
        }
      }
    };

    useImperativeHandle(ref, () => {
      return { setSize, setSizeParameters };
    });

    const annotationClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!disabled) {
        onClick && onClick(e);
        AnnotationController.editAnnotation(annotationId);
      }
    };
    return (
      <div
        ref={containerRef}
        style={{
          position: 'absolute',
          float: 'left',
          width: '100%',
          height: '100%',
          filter: highlight
            ? 'drop-shadow(0 0 4px rgb(194,39,134))'
            : 'initial',
          zIndex: highlight || mode === 'editing' ? 2 : 'initial',
          visibility: visible || highlight ? 'visible' : 'hidden',
        }}
      >
        {mode === 'editing' && <Portal target={'#toolbar'}>{toolbar}</Portal>}
        <Rnd
          disableDragging={mode === 'display'}
          style={{
            pointerEvents: 'all',
          }}
          size={{
            width: `${dimensions.width}px`,
            height: `${dimensions.height}px`,
          }}
          maxWidth={'100%'}
          maxHeight={'100%'}
          onMouseDown={(e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          lockAspectRatio={lockAspectRatio}
          position={{
            x:
              dimensions.left -
              (containerRef.current
                ? containerRef.current.getBoundingClientRect().left
                : 0),
            y:
              dimensions.top -
              (containerRef.current
                ? containerRef.current.getBoundingClientRect().top
                : 0),
          }}
          onDragStop={(e, d) => {
            if (containerRef.current) {
              const rect = containerRef.current.getBoundingClientRect();
              const current = { x: d.x + rect.left, y: d.y + rect.top };
              const delta = ndcToCndc(
                documentToNdc(current, containerRef.current || undefined)
              );
              const position = {
                x: delta.x,
                y: delta.y,
              };
              setDimensions({
                width: dimensions.width,
                height: dimensions.height,
                top: current.y,
                left: current.x,
              });

              persistAnnotation(position);
            }
          }}
          onResizeStop={(_e, direction, ref, delta, position) => {
            if (containerRef.current) {
              const rect = containerRef.current.getBoundingClientRect();
              const childRect = ref.getBoundingClientRect();
              const current = {
                x: position.x + rect.left,
                y: position.y + rect.top,
              };
              const location = ndcToCndc(
                documentToNdc(current, containerRef.current || undefined)
              );
              const size = documentSizeToNds(
                {
                  x: childRect.width,
                  y: childRect.height,
                },
                containerRef.current || undefined
              );
              setDimensions({
                width: childRect.width,
                height: childRect.height,
                top: current.y,
                left: current.x,
              });

              persistAnnotation({
                ...location,
                size: {
                  width: size.x,
                  height: size.y,
                },
              });
            }
          }}
          enableResizing={mode !== 'display'}
        >
          <div
            onClick={annotationClick}
            onMouseEnter={(e) => {
              if (playbackState !== 'playing') {
                AnnotationController.setHovered(annotationId);
                AnnotationController.setHighlight(annotationId, true);
              }
            }}
            onMouseLeave={(e) => {
              AnnotationController.setHovered();
              AnnotationController.setHighlight(annotationId, false);
            }}
            style={{
              width: '100%',
              height: '100%',
              cursor: disabled
                ? 'default'
                : mode === 'editing'
                ? 'move'
                : 'pointer',
              position: 'absolute',
              border: mode === 'editing' ? '2px dashed rgb(193, 194, 197)' : 0,
              marginLeft: mode === 'editing' ? '-2px' : 0,
              marginTop: mode === 'editing' ? '-2px' : 0,
              boxSizing: 'content-box',
            }}
          >
            {children}
          </div>
        </Rnd>
      </div>
    );
  }
);
