import { AnyEventObject } from 'xstate';
import {
  AnimationController,
  AnnotationController,
  StepController,
} from '../controller';

export const showAnnotationsInSelectedStep = () => {
  const selectedStep = StepController.getSelectedStep();
  if (selectedStep) {
    selectedStep.annotations.forEach((annotationId) => {
      AnnotationController.setVisibility(annotationId, true);
    });
  }
};

export const hideAnnotationsInSelectedStep = () => {
  const selectedStep = StepController.getSelectedStep();
  if (selectedStep) {
    selectedStep.annotations.forEach((annotationId) => {
      AnnotationController.setVisibility(annotationId, false);
    });
  }
};

export const showAnnotationsInPlayingSequence = () => {
  const stepIds = AnimationController.getCurrentStepIds();
  stepIds?.forEach((id) => {
    AnnotationController.showAnnotationsInStep(id);
  });
  /*
  TODO: rework
  const playingSequence = AnimationController.getCurrentClip();
  if (playingSequence) {
    playingSequence.stepReferences.forEach((stepId) => {
      const step = StepController.getStep(stepId);
      if (step) {
        step.annotations.forEach((annotationId) => {
          AnnotationController.setVisibility(annotationId, true);
        });
      }
    });
  }
  */
};

export const hideAnnotationsInPlayingSequence = () => {
  /*
  TODO: rework
  const playingSequence = AnimationController.getCurrentClip();
  if (playingSequence) {
    playingSequence.stepReferences.forEach((stepUuid) => {
      const step = StepController.getStep(stepUuid);
      if (step) {
        step.annotations.forEach((annotationId) => {
          AnnotationController.setVisibility(annotationId, false);
        });
      }
    });
  }
  */
};

export const hideAllAnnotations = () => {
  AnnotationController.hideAllAnnotations();
};

export const unhighlightAllAnnotations = () => {
  AnnotationController.unhighlightAllAnnotations();
};

export const stopEditingAnnotations = () => {
  AnnotationController.stopEditing();
};

export const isNotEditingAnnotation = (): boolean => {
  return !AnnotationController.isEditing();
};
