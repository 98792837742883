import { Hud, OrthographicCamera } from '@react-three/drei';
import { ThreeEvent, useFrame, useThree } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import {
  CanvasTexture,
  Color,
  Face,
  Material,
  Matrix3,
  Mesh,
  MeshPhongMaterial,
  Object3D,
  Vector3,
} from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { viewCube } from './ViewCubeMesh';

const generateTexture = (color: Color, text: string) => {
  const canvas = document.createElement('canvas');
  canvas.width = 512;
  canvas.height = 512;
  const context = canvas.getContext('2d');
  if (context) {
    context.fillStyle = '#' + color.getHexString();
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.strokeStyle = '#ffffff';
    context.strokeRect(0, 0, canvas.width, canvas.height);
    context.font = '100px Inter var, Arial, sans-serif';
    context.textAlign = 'center';
    context.fillStyle = '#ffffff';
    context.fillText(text, 256, 306);
    return new CanvasTexture(canvas);
  }
  return;
};

type ViewCubeProps = {
  marginX?: number;
  marginY?: number;
};
export const ViewCube = ({ marginX = 80, marginY = 80 }: ViewCubeProps) => {
  const size = useThree((state) => state.size);
  const { camera } = useThree();
  const cubeRef = useRef<Object3D>();
  const hoveredFace = useRef<Face | undefined | null>();

  useFrame(() => {
    if (cubeRef.current) {
      cubeRef.current.quaternion.setFromRotationMatrix(
        camera.matrix.clone().invert()
      );
    }
  }, 0);

  const cube = useMemo(() => {
    const namedMaterials = ['Top', 'Bottom', 'Left', 'Right', 'Front', 'Back'];
    const loader = new OBJLoader();
    const object = loader.parse(viewCube);
    const model = object.children.at(0) as Mesh;
    const normals = model.geometry.attributes['normal'];
    const materials = model.material as Array<Material>;
    model.geometry.groups.forEach((group) => {
      if (group.materialIndex) {
        const material = materials[group.materialIndex] as MeshPhongMaterial;
        const faceColor = namedMaterials.includes(material.name)
          ? new Color(
              Math.abs(normals.getX(group.start)),
              Math.abs(normals.getY(group.start)),
              Math.abs(normals.getZ(group.start))
            )
              .multiplyScalar(0.2) // Darken the colour
              .offsetHSL(-0.023, 0, 0) // Rotate the colour by -0.023 units to reach a more harmonious tone
          : new Color(1, 1, 1);
        const texture = generateTexture(
          faceColor,
          namedMaterials.includes(material.name) ? material.name : ''
        );
        if (texture) {
          material.map = texture;
        } else {
          material.color = faceColor;
        }
      }
    });
    return object;
  }, []);

  const x = -size.width / 2 + marginX;
  const y = -size.height / 2 + marginY;

  return (
    <Hud renderPriority={1}>
      <OrthographicCamera makeDefault position={[0, 0, 200]} />
      <primitive
        position={[x, y, 0]}
        scale={35}
        object={cube}
        ref={cubeRef}
        onClick={(e: ThreeEvent<Event>) => {
          const cameraPivot = new Vector3(0, 0, -camera.distance).applyMatrix4(
            camera.matrix
          );
          if (e.face) {
            const normal = e.face.normal;
            camera.position.copy(
              cameraPivot.clone().add(normal.multiplyScalar(camera.distance))
            );
            camera.updateMatrix();
            camera.lookAt(cameraPivot);
            camera.dispatchEvent({ type: 'change' });
          }
        }}
        onPointerMove={(e: ThreeEvent<Event>) => {
          if (e.face) {
            const materialIndex = e.face.materialIndex;
            const materials = (e.object as Mesh).material as Array<Material>;
            if (materials.length) {
              const material = materials.at(materialIndex);
              (material as MeshPhongMaterial).color = new Color('#999');

              if (
                hoveredFace.current &&
                hoveredFace.current.materialIndex !== materialIndex
              ) {
                const material = materials.at(
                  hoveredFace.current.materialIndex
                );
                (material as MeshPhongMaterial).color = new Color('#fff');
              }
            }
          }
          hoveredFace.current = e.face;
        }}
        onPointerLeave={(e: ThreeEvent<Event>) => {
          if (hoveredFace.current) {
            const materials = (e.object as Mesh).material as Array<Material>;
            if (materials.length) {
              const material = materials.at(hoveredFace.current.materialIndex);
              (material as MeshPhongMaterial).color = new Color('#fff');
            }
          }
        }}
      />
      <ambientLight intensity={2} />
    </Hud>
  );
};
