import { axiosHandler } from '../utils/axios-utils';
import { CreatePDFExport, CreateVideoExport } from './types/job.mutation.types';
import { JobDto } from '@assemblio/shared/dtos';

export const deleteJob = async (instructionId: string, jobId: string) => {
  return axiosHandler('delete', `/instructions/${instructionId}/jobs/${jobId}`);
};

export const retryJob = async (instructionId: string, jobId: string) => {
  return axiosHandler(
    'post',
    `/instructions/${instructionId}/jobs/${jobId}/retry`
  );
};

export const fetchJobsForInstruction = async (
  instructionId: string
): Promise<Array<JobDto>> => {
  return axiosHandler('get', `/instructions/${instructionId}/jobs`);
};

export const exportVideo = async (
  videoExport: CreateVideoExport
): Promise<JobDto> => {
  return axiosHandler('post', `/instructions/${videoExport.id}/export/video`, {
    data: videoExport.data,
  });
};

export const exportPDF = async (pdfExport: CreatePDFExport): Promise<JobDto> => {
  return axiosHandler('post', `/instructions/${pdfExport.id}/export/document`, {
    data: pdfExport.data,
  });
};
