import { Step } from '@assemblio/shared/next-types';
import produce from 'immer';
import _ from 'lodash';
import { Unsubscribe } from 'nanoevents';
import {
  MathUtils,
  Matrix4,
  OrthographicCamera,
  Quaternion,
  Vector3,
} from 'three';
import {
  AnnotationController,
  ImperativeModelController,
  ModelController,
  SequenceController,
  UIController,
} from '.';
import { AnimationStore, UI, useCanvasStore, useUIStore } from '../stores';
import { useAnimationStore } from '../stores/AnimationStore';
import { MachineController } from './MachineController';
import { StepController } from '.';
import { Clip, Tartis, Transform } from './animation/Tartis';
import { TartisEvents } from './animation/Tartis.types';
import { dataToQuaternion, dataToVector3 } from '../helper';
import { ModelVisibilityController } from './ModelVisibilityController';

export namespace AnimationController {
  type AnimationOptions = {
    onStepComplete?: () => void;
    onAnimationComplete?: () => void;
    omitCameraAnimations?: boolean;
  };

  const tartis = new Tartis();
  const stepToClipIndex = new Map<
    string,
    { prelude?: string; main?: string }
  >();
  const clipToStepIndex = new Map<string, Set<string>>();

  export const play = (loop: boolean, fromStep?: string) => {
    ModelVisibilityController.setAllPartsVisible();

    const clipDefinition = fromStep ? stepToClipIndex.get(fromStep) : undefined;
    tartis.play(clipDefinition?.main);
    tartis.loop = loop;
  };

  export const resume = () => {
    tartis.play();
  };

  export const finished = () => {
    return tartis.finished();
  };

  export const pause = () => {
    tartis.pause();
  };

  export const stop = () => {
    tartis.stop();
  };

  export const next = () => {
    tartis.next();
  };

  export const previous = () => {
    tartis.previous();
  };

  export const advance = (ms: number) => {
    MachineController.advanceAnimation();
    tartis.advance(ms);
  };

  export const seek = (fraction: number) => {
    tartis.seek(fraction);
  };

  export const getClipIdForStepId = (stepId: string) => {
    return stepToClipIndex.get(stepId);
  };

  const getStepIdForClipId = (clipId: string) => {
    return clipToStepIndex.get(clipId);
  };

  export const applySequence = (clipIds: Array<string>) => {
    const omittedClips = tartis
      .getClips()
      .filter((clip) => !clipIds.includes(clip.id))
      .map((clip) => clip.id);
    tartis.enableClips(omittedClips, false);
  };

  const registerEventHandlers = ({
    onStepComplete,
    onAnimationComplete,
    omitCameraAnimations,
  }: AnimationOptions = {}) => {
    /* Register Animation Events */

    tartis.on(
      'clipFinished',
      // Check if the clip is associated with a step, if so, trigger callback
      (clipId) => {
        const stepSet = clipToStepIndex.get(clipId);
        if (stepSet) {
          stepSet.forEach((stepId) => {
            UIController.unhighlightStep(stepId);
            AnnotationController.showAnnotationsInStep(stepId, false);
          });
          onStepComplete && onStepComplete();
        }
      }
    );
    onAnimationComplete && tartis.on('finish', () => onAnimationComplete());

    tartis.on('finish', () => {
      MachineController.stopAnimation();
    });
    tartis.on('clipStarted', (clipId) => {
      const stepSet = clipToStepIndex.get(clipId);
      const clip = tartis.getClipById(clipId);
      if (stepSet && clip && clip.enabled) {
        stepSet.forEach((stepId) => {
          if (tartis.state !== 'stopped') {
            UIController.highlightStep(stepId);
          }
          UIController.selectStepById(stepId);
          AnnotationController.showAnnotationsInStep(stepId);
          const groupId = StepController.getStep(stepId)?.stepGroupId;
          if (groupId) {
            UIController.expandStepGroup(groupId);
            SequenceController.setSelectedStepGroup(groupId);
          }
        });
      }
    });
  };

  export const createAnimations = (animationOptions: AnimationOptions = {}) => {
    tartis.reset();

    registerEventHandlers(animationOptions);

    /* Clear indices */
    stepToClipIndex.clear();
    clipToStepIndex.clear();

    const camera = useCanvasStore.getState().camera;
    // Fail if camera doesn't exist
    if (!camera)
      throw new Error("Camera doesn't exist. Couldn't create animations.");

    const steps = StepController.getAllStepsFlat();
    const alignmentSteps = steps.filter((step) => step.type === 'alignment');
    alignmentSteps.forEach((step) => {
      step.data.parts.forEach((partInStep) => {
        ModelVisibilityController.setPartTransparency(
          partInStep.partGltfIndex,
          1
        );
      });
    });
    let previousStep: Step | undefined = undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stepTransforms = new Array<Transform<any, any>>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stepInitialization = new Array<Transform<any, any>>();
    const usedParts = new Set<number>();
    const includedSteps = new Array<string>();
    [...steps].reverse().forEach((step) => {
      const clip = new Clip({ step });

      const cameraTransform = createCameraTransform(
        camera as OrthographicCamera,
        step,
        previousStep
      );
      includedSteps.push(step.id);
      if (!step.data) return;
      if (step.data.parts.length === 0) {
        stepTransforms.push(new Transform(0, [0], _.noop));
      }
      const firstPathElement = step.data.path.at(0);
      if (firstPathElement) {
        const pathMatrix = new Matrix4()
          .compose(
            dataToVector3(firstPathElement.position),
            dataToQuaternion(firstPathElement.rotation),
            new Vector3(1, 1, 1)
          )
          .invert();
        const offsets = step.data.parts.reduce((offsetMap, part) => {
          if (part.start) {
            const { position, rotation } = part.start;
            offsetMap.set(
              part.partGltfIndex,
              pathMatrix
                .clone()
                .multiply(
                  new Matrix4().compose(
                    dataToVector3(position),
                    dataToQuaternion(rotation),
                    new Vector3(1, 1, 1)
                  )
                )
            );
          }
          return offsetMap;
        }, new Map<number, Matrix4>());
        const models = step.data.parts.map((part) => {
          return {
            gltfIndex: part.partGltfIndex,
            model: ModelController.getModelByGltfIndex(part.partGltfIndex),
          };
        });
        const pathTransforms = [...step.data.path].reverse().map((element) => {
          return {
            position: dataToVector3(element.position),
            rotation: dataToQuaternion(element.rotation),
          };
        });
        const transform = new Transform(
          models,
          pathTransforms,
          (alpha, target, from, to) => {
            const position = new Vector3().lerpVectors(
              from.position,
              to.position,
              alpha
            );
            const rotation = new Quaternion().slerpQuaternions(
              from.rotation,
              to.rotation,
              alpha
            );
            const targetMatrix = new Matrix4().compose(
              position,
              rotation,
              new Vector3(1, 1, 1)
            );
            target.forEach((part) => {
              const offsetMatrix = offsets.get(part.gltfIndex);
              if (offsetMatrix && part.model) {
                targetMatrix
                  .clone()
                  .multiply(offsetMatrix)
                  .decompose(
                    part.model.position,
                    part.model.quaternion,
                    part.model.scale
                  );
              }
            });
          }
        );

        const transparencyInitialization = new Transform(
          models.filter(({ gltfIndex }) => !usedParts.has(gltfIndex)),
          [0, 1],
          (alpha, target, from, to) => {
            const transparency = MathUtils.lerp(from, to, alpha);
            target.forEach((part) => {
              if (part.model) {
                part.model.visible = transparency !== 0;
                ImperativeModelController.setModelTransparency(
                  part.model,
                  transparency
                );
              }
            });
          }
        );

        const positionInitialization = new Transform(
          models,
          [pathTransforms.at(0)],
          (alpha, target, from, to) => {
            target.forEach((part) => {
              const offsetMatrix = offsets.get(part.gltfIndex);
              if (part.model && from && offsetMatrix) {
                new Matrix4()
                  .compose(from.position, from.rotation, new Vector3(1, 1, 1))
                  .clone()
                  .multiply(offsetMatrix)
                  .decompose(
                    part.model.position,
                    part.model.quaternion,
                    part.model.scale
                  );
              }
            });
          }
        );
        stepInitialization.push(positionInitialization);
        if (step.type !== 'alignment') {
          stepInitialization.push(transparencyInitialization);
        }
        step.data.parts.forEach((part) => {
          usedParts.add(part.partGltfIndex);
        });

        stepTransforms.push(transform);
      }
      if (!step.playWithAbove) {
        clip.addSimultaneous(stepTransforms, step.animationSpeed * 1000);

        if (
          !animationOptions.omitCameraAnimations ||
          step.data.parts.length <= 0
        ) {
          const cameraClip = new Clip();
          cameraClip.enabled = clip.enabled;
          cameraClip.transitional = true;
          cameraClip.addSimultaneous<any, any>(
            [...stepInitialization, cameraTransform],
            500
          );
          tartis.add(cameraClip);
          includedSteps.forEach((stepId) => {
            associateClipAndStep(stepId, cameraClip.id, true);
          });
        }
        tartis.add(clip);

        includedSteps.forEach((stepId) => {
          associateClipAndStep(stepId, clip.id);
        });
        includedSteps.length = 0;
        stepTransforms.length = 0;
        stepInitialization.length = 0;
        previousStep = step;
      }
    });
  };

  export const on = <E extends keyof TartisEvents>(
    event: E,
    callback: TartisEvents[E]
  ): Unsubscribe => {
    return tartis.on(event, callback);
  };

  export const setLoopAnimations = (loop: boolean): void => {
    useAnimationStore.setState(
      produce<AnimationStore>((state) => {
        state.loop = loop;
      })
    );
    tartis.loop = loop;
  };

  export const toggleLoopAnimations = (): void => {
    setLoopAnimations(!useAnimationStore.getState().loop);
  };

  export const setIsAnimating = (active: boolean) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.isAnimating = active;
      })
    );
  };

  export const skipToEndOfClip = (stepId?: string) => {
    if (stepId) {
      const clip = getClipIdForStepId(stepId);
      if (clip && clip.main) {
        tartis.goToClipEnd(clip.main);
      }
    } else {
      tartis.goToClipEnd();
    }
  };

  export const getCurrentStepIds = () => {
    const currentClipId = tartis.getCurrentClipId();
    if (currentClipId) {
      const stepIds = getStepIdForClipId(currentClipId);
      if (stepIds) {
        return Array.from(stepIds.values());
      }
    }
    return;
  };

  export const getAnimationProgress = () => {
    return tartis.progress;
  };

  export const getAnimationLength = () => {
    return tartis.length;
  };

  const createCameraTransform = (
    camera: OrthographicCamera,
    currentStep: Step,
    previousStep?: Step
  ): Transform<
    OrthographicCamera,
    { position: Vector3; rotation: Quaternion; zoom: number }
  > => {
    const previousCameraRotation = camera.quaternion.clone();
    const previousCameraPosition = camera.position.clone();
    let previousCameraZoom = camera.zoom;
    if (previousStep) {
      const { rotation: previousRotation, position: previousPosition } =
        previousStep.cameraSettings.transform;
      previousCameraRotation.set(
        previousRotation.x,
        previousRotation.y,
        previousRotation.z,
        previousRotation.w
      );
      previousCameraPosition.set(
        previousPosition.x,
        previousPosition.y,
        previousPosition.z
      );
      previousCameraZoom = previousStep.cameraSettings.zoom;
    }
    const { rotation: currentRotation, position: currentPosition } =
      currentStep.cameraSettings.transform;

    return new Transform(
      camera,
      [
        {
          position: previousCameraPosition,
          rotation: previousCameraRotation,
          zoom: previousCameraZoom,
        },
        {
          position: dataToVector3(currentPosition),
          rotation: dataToQuaternion(currentRotation),
          zoom: currentStep.cameraSettings.zoom,
        },
      ],
      (alpha, target, from, to) => {
        target.matrixAutoUpdate = true;
        const startPosition = from.position.clone();
        const endPosition = to.position.clone();
        const xzStartPosition = new Vector3(
          startPosition.x,
          0,
          startPosition.z
        );
        const xzEndPosition = new Vector3(endPosition.x, 0, endPosition.z);

        const orientation = new Quaternion().slerpQuaternions(
          from.rotation,
          to.rotation,
          alpha
        );
        const zoom = MathUtils.lerp(from.zoom, to.zoom, alpha);
        const y = MathUtils.lerp(startPosition.y, endPosition.y, alpha);

        const center = new Vector3();
        center.y = 0;
        const centeredStartPosition = xzStartPosition.clone().sub(center);
        const centeredEndPosition = xzEndPosition.clone().sub(center);

        const startPositionDistance = centeredStartPosition.length();
        const endPositionDistance = centeredEndPosition.length();

        const lengthDifference = MathUtils.lerp(
          0,
          endPositionDistance - startPositionDistance,
          alpha
        );

        const clockwise =
          centeredStartPosition.clone().cross(centeredEndPosition.clone()).y < 0
            ? -1
            : 1;
        const angle =
          centeredStartPosition.clone().angleTo(centeredEndPosition) *
          clockwise;

        const rotation = new Quaternion().slerp(
          new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), angle),
          alpha
        );

        const rotatedPosition = centeredStartPosition
          .clone()
          .applyQuaternion(rotation)
          .add(center);

        const newPosition = rotatedPosition
          .clone()
          .add(
            rotatedPosition.clone().normalize().multiplyScalar(lengthDifference)
          );

        newPosition.y = y;

        target.position.copy(newPosition);
        target.setRotationFromQuaternion(orientation);
        target.zoom = zoom;
        target.updateProjectionMatrix();
        target.dispatchEvent({ type: 'change' });
      }
    );
  };

  const associateClipAndStep = (
    stepId: string,
    clipId: string,
    isPrelude = false
  ) => {
    if (!clipToStepIndex.has(clipId)) {
      clipToStepIndex.set(clipId, new Set<string>());
    }
    if (!stepToClipIndex.has(stepId)) {
      stepToClipIndex.set(stepId, {});
    }
    clipToStepIndex.get(clipId)?.add(stepId);
    if (isPrelude) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      stepToClipIndex.get(stepId)!.prelude = clipId;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      stepToClipIndex.get(stepId)!.main = clipId;
    }
  };
}
