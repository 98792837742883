import { useMutation } from '@tanstack/react-query';
import {
  CreateSequence,
  PatchSequence,
  SequenceGroupReference,
  SequencePartReference,
} from './types/sequences.mutation.types';
import { createSequence, excludePartFromSequence, renameSequence, includePartInSequence } from './sequence.requests';
import { notifications } from '@mantine/notifications';

export const useSequenceCreate = () => {
  return useMutation((create: CreateSequence) => createSequence(create), {
    onError: (_error) => {
      notifications.show({
        id: 'create-sequence',
        message: 'Sequence could not be created',
        color: 'red',
      });
    },
  });
};

export const useSequenceRename = () => {
  return useMutation((patch: PatchSequence) => renameSequence(patch), {
    onError: (_error) => {
      notifications.show({
        id: 'rename-sequence',
        message: 'Sequence could not be modified',
        color: 'red',
      });
    },
  });
};

export const useIncludePart = () => {
  return useMutation((partReference: SequencePartReference) => includePartInSequence(partReference), {
    onError: (_error) => {
      notifications.show({
        id: 'hide-group',
        message: 'Part could not be included',
        color: 'red',
      });
    },
  });
};

export const useExcludePart = () => {
  return useMutation((partReference: SequencePartReference) => excludePartFromSequence(partReference), {
    onError: (_error) => {
      notifications.show({
        id: 'hide-group',
        message: 'Part could not be excluded',
        color: 'red',
      });
    },
  });
};
