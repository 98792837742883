import { axiosHandler } from '../utils/axios-utils';
import { CreateSequence, PatchSequence, SequencePartReference } from './types/sequences.mutation.types';

export const createSequence = async ({ id, data }: CreateSequence) => {
  return await axiosHandler('post', `/instructions/${id}/sequences`, { data });
};

export const renameSequence = async ({ id, data }: PatchSequence) => {
  return await axiosHandler('patch', `/sequences/${id}/rename`, { data });
};

export const excludePartFromSequence = async ({ id, data }: SequencePartReference) => {
  return await axiosHandler('put', `/sequences/${id}/parts/exclude`, { data });
};

export const includePartInSequence = async ({ id, data }: SequencePartReference) => {
  return await axiosHandler('delete', `/sequences/${id}/parts/exclude`, { data });
};
