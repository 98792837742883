import { CameraControlsAPI } from '@assemblio/frontend/types';
import { useThree } from '@react-three/fiber';
import { forwardRef, useEffect, useMemo } from 'react';
import {
  Camera,
  EventDispatcher,
  Matrix4,
  OrthographicCamera,
  Vector2,
  Vector3,
} from 'three';
import { documentToNdc } from './CoordinateSystems';

type InteractionFunction = (camera: Camera, delta: Vector2) => void;

type Interactions = {
  rotate: InteractionFunction;
  pan: InteractionFunction;
  zoom: InteractionFunction;
};
type Interaction = keyof Interactions | undefined;

const BUTTON_MAPPINGS: { [key: number]: Interaction } = {
  0: 'rotate',
  1: 'zoom',
  2: 'pan',
};

const interactions: Interactions = {
  rotate: (camera: Camera, delta: Vector2) => {
    // The target is always placed relative to the camera
    // distance units along the negative relative z-Axis
    const target = new Vector3(0, 0, -1)
      .multiplyScalar(camera.distance)
      .applyQuaternion(camera.quaternion)
      .add(camera.position);

    // Read from bottom to top, as that is the order in which the
    // operations are applied.
    camera.applyMatrix4(
      new Matrix4()
        // 4. Move Camera center back to original position
        .makeTranslation(target.clone())
        // 3. Rotate camera around the global y-Axis
        .multiply(
          new Matrix4().makeRotationAxis(
            new Vector3(0, 1, 0),
            delta.x * Math.PI * 2
          )
        )
        // 2. Rotate the camera around the local x-Axis
        .multiply(
          new Matrix4().makeRotationAxis(
            new Vector3(1, 0, 0).applyQuaternion(camera.quaternion),
            delta.y * Math.PI * 2
          )
        )
        // 1. Center camera on the target point
        .multiply(
          new Matrix4().makeTranslation(target.clone().multiplyScalar(-1))
        )
    );
  },

  pan: (camera: Camera, delta: Vector2) => {
    const orthographicCamera = camera as OrthographicCamera;

    // Create local x-Axis, multiply by the movement delta
    const xAxisMovement = new Vector3(1, 0, 0)
      .applyQuaternion(camera.quaternion)
      .multiplyScalar(delta.x * (1 / orthographicCamera.zoom));

    // Multiply global y-Axis by movement delta
    const yAxisMovement = new Vector3(0, 1, 0).multiplyScalar(
      -delta.y * (1 / orthographicCamera.zoom)
    );

    // Add movements to position
    camera.position.add(xAxisMovement).add(yAxisMovement);
    camera.dispatchEvent({ type: 'change' });
  },

  zoom: (camera: Camera, delta: Vector2) => {
    // Multiply the y-delta with the current camera zoom,
    // then add it to the current camera zoom to make the
    // zoom steps smaller the smaller the zoom gets and vice
    // versa
    const orthographicCamera = camera as OrthographicCamera;
    orthographicCamera.zoom += orthographicCamera.zoom * delta.y;
    // Updating zoom necessitates updating the projection matrix
    orthographicCamera.updateProjectionMatrix();
    camera.dispatchEvent({ type: 'change' });
  },
};

class Controls extends EventDispatcher<any> implements CameraControlsAPI {
  private interaction: Interaction;
  private initialDomPosition = new Vector2();
  private domElement: HTMLElement;
  public enabled = true;
  public connect: () => void;
  public dispose: () => void;
  public setTarget: (target: Vector3) => void;
  constructor(camera: Camera, domElement: HTMLElement) {
    super();
    this.initialDomPosition = new Vector2();
    this.interaction = undefined;
    this.domElement = domElement;

    const onContextMenu = (e: MouseEvent) => {
      if (!this.enabled) return;

      e.preventDefault();
    };

    const onWheel = (e: WheelEvent) => {
      if (!this.enabled) return;
      interactions.zoom(camera, new Vector2(0, Math.sign(e.deltaY) / -30));
    };

    const pointerMove = (e: MouseEvent) => {
      if (!this.enabled) return;
      if (this.interaction) {
        const currentDomPosition = new Vector2(e.clientX, e.clientY);
        const delta = this.initialDomPosition.clone().sub(currentDomPosition);
        const ndcDelta = documentToNdc(delta);
        interactions[this.interaction](
          camera,
          new Vector2(ndcDelta.x, ndcDelta.y)
        );
        this.initialDomPosition.copy(currentDomPosition);

        // We can probably replace these with one event now
        // Left in for legacy purposes
        this.dispatchEvent({ type: 'update' });
        camera.dispatchEvent({ type: 'change' });
      }
    };

    const pointerUp = () => {
      if (!this.enabled) return;
      this.interaction = undefined;
      this.domElement.ownerDocument.removeEventListener(
        'pointermove',
        pointerMove
      );
      this.domElement.ownerDocument.removeEventListener('pointerup', pointerUp);
      this.dispatchEvent({ type: 'end' });
    };

    const pointerDown = (e: MouseEvent) => {
      if (!this.enabled) return;
      this.initialDomPosition.set(e.clientX, e.clientY);
      this.interaction = BUTTON_MAPPINGS[e.button];
      this.domElement.ownerDocument.addEventListener(
        'pointermove',
        pointerMove
      );
      this.domElement.ownerDocument.addEventListener('pointerup', pointerUp);
      this.dispatchEvent({ type: 'start' });
    };

    this.connect = () => {
      this.domElement.addEventListener('pointerdown', pointerDown);
      this.domElement.addEventListener('contextmenu', onContextMenu);
      this.domElement.addEventListener('wheel', onWheel);
    };

    this.dispose = () => {
      this.domElement.removeEventListener('pointerdown', pointerDown);
      this.domElement.removeEventListener('contextmenu', onContextMenu);
      this.domElement.removeEventListener('wheel', onWheel);
      this.domElement.ownerDocument.removeEventListener(
        'pointermove',
        pointerMove
      );
      this.domElement.ownerDocument.removeEventListener('pointerup', pointerUp);
    };

    this.setTarget = (target: Vector3) => {
      camera.distance = target.distanceTo(camera.position);
      camera.lookAt(target);
    };
  }
}

export interface OrbiterProps {
  active?: boolean;
}

export const Orbiter = forwardRef<CameraControlsAPI, OrbiterProps>(
  ({ active }, ref) => {
    const { camera, gl, set, get } = useThree();

    const controls = useMemo(
      () => new Controls(camera, gl.domElement),
      [camera, gl.domElement]
    );

    useEffect(() => {
      controls.connect();
      return () => {
        controls.dispose();
      };
    }, [controls]);

    // Make controls play nice with r3f by registering them as defaults that have an enabled attribute.
    // https://github.com/pmndrs/drei/blob/9edcade6e4cb3aba2ed11ff5432cfcae76189548/src/core/OrbitControls.tsx#L96C11-L96C11
    // https://github.com/pmndrs/drei/blob/9edcade6e4cb3aba2ed11ff5432cfcae76189548/src/core/TransformControls.tsx#L8C6-L8C20
    // https://github.com/pmndrs/drei/blob/9edcade6e4cb3aba2ed11ff5432cfcae76189548/src/core/TransformControls.tsx#L81
    useEffect(() => {
      const old = get().controls;
      set({ controls });
      return () => set({ controls: old });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controls]);

    return <primitive ref={ref} object={controls} />;
  }
);
