import { Button, Divider, Group, Skeleton, Stack, Text } from '@mantine/core';
import {
  NextInstruction,
  NextInstructionVariant,
} from '../../../types/project-structure.types';
import {
  SyncProfilePairEditTable,
  SyncProfilePairEditTableSkeleton,
} from '../components/SyncProfileEditTable';
import {
  useInstructionSyncProfileQuery,
  useInstructionSyncResolve,
} from '@assemblio/frontend/data-access';
import { SyncProfilePairs } from '@assemblio/shared/next-types';
import { ProjectRoutingParams } from '../../../types';
import { useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { InstructionSyncProfileVisualization } from '../components/InstructionSyncProfileVisualization';

interface EditVariantSyncProfileContentProps {
  sourceVariant: NextInstructionVariant;
  targetInstruction: NextInstruction;
  onClose: () => void;
}

export const EditVariantSyncProfileContent = ({
  sourceVariant,
  targetInstruction,
  onClose,
}: EditVariantSyncProfileContentProps) => {
  const { projectId } =
    useParams<ProjectRoutingParams>() as ProjectRoutingParams;
  const { data, isLoading, isError } = useInstructionSyncProfileQuery({
    sourceId: sourceVariant.id,
    targetId: targetInstruction.id,
  });

  const similarPartSum =
    (data?.pairs.resolved.keep.length ?? 0) +
    (data?.pairs.resolved.replace.length ?? 0);

  const deprecatedPartSum = data?.parts.deprecated.length
    ? data.parts.deprecated.length + 1
    : 0;

  const replacePartSum = data?.pairs.replace.length
    ? data.pairs.replace.length + 1
    : 0;

  const resolveMutation = useInstructionSyncResolve();

  const [updatedPairs, setUpdatedPairs] = useState<
    SyncProfilePairs['resolved'] | undefined
  >(undefined);

  const updateSyncProfilePairs = () => {
    if (!updatedPairs) return;
    resolveMutation.mutate(
      {
        sourceId: sourceVariant.id,
        targetId: targetInstruction.id,
        projectId,
        resolve: updatedPairs,
      },
      {
        onSuccess: () => {
          notifications.show({
            id: 'sync-resolve-success',
            message: 'Synchronization Issues resolved',
            color: 'green',
          });
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    setUpdatedPairs(undefined);
    onClose();
  };

  return (
    <Stack>
      <Text size="xl">Edit Synchronization Part Assigment</Text>
      <Stack>
        {/* TODO: improve wording */}
        <Group justify="center" my={'xs'}>
          <InstructionSyncProfileVisualization
            sourceName={sourceVariant.name}
            targetName={targetInstruction.name}
            state={sourceVariant.status}
          />
        </Group>
        {isLoading ? (
          <Skeleton w={'70%'} h={'50px'} />
        ) : (
          <Stack gap={'xs'}>
            <Text size="sm">
              <b>{targetInstruction.name}&nbsp;</b>to
              <b>&nbsp;{sourceVariant.name}&nbsp;</b>
              has {replacePartSum}&nbsp; identical parts.
            </Text>
            <Text size="sm">
              {similarPartSum}&nbsp;
              {similarPartSum === 1 ? 'Part has' : 'Parts have'}&nbsp; been
              recognized as similar.
            </Text>
            <Text size="sm">
              {deprecatedPartSum}&nbsp;
              {deprecatedPartSum === 1 ? 'Part has' : 'Parts have'}
              &nbsp; been recognized as deprecated.
            </Text>
            <Text size="sm">
              You can edit the link between the parts for future
              synchronization.
            </Text>
          </Stack>
        )}
        <Divider />
      </Stack>
      {!data || isLoading ? (
        <SyncProfilePairEditTableSkeleton />
      ) : (
        <SyncProfilePairEditTable
          onChange={(updatedPairs) => setUpdatedPairs(updatedPairs)}
          pairs={data.pairs}
          label="Select how these parts should be handeled in the future:"
        />
      )}
      <Button.Group
        style={{
          justifyContent: 'end',
        }}
      >
        <Button
          disabled={!updatedPairs || isError || similarPartSum === 0}
          loading={resolveMutation.isLoading}
          onClick={updateSyncProfilePairs}
        >
          Update
        </Button>
        <Button variant={'outline'} onClick={handleClose}>
          Cancel
        </Button>
      </Button.Group>
    </Stack>
  );
};
