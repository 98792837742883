export enum JobType {
  UNDEFINED = 'undefined',
  GLTF = 'gltf',
  GLTF_PARSING = 'gltf-parsing',
  ANALYSIS_COMPARISON = 'analysis-comparison',
  EXPORT_VIDEO = 'export-video',
  EXPORT_PDF = 'pdf',
  EXPORT_DOCX = 'docx',
}

export enum ExportJobType {
  EXPORT_VIDEO = 'export-video',
  EXPORT_PDF = 'pdf',
  EXPORT_DOCX = 'docx',
}

export enum JobQueues {
  CAD_FILE_PROCESSING = 'cad-file-processing',
  EXPORT_VIDEO = 'export-video',
  EXPORT_DOCUMENT = 'export-document',
  GLTF_PARSING = 'initialization',
  ANALYSIS_COMPARISON = 'analysis-comparison',
}
