import { ContextMenuHandler } from '@assemblio/frontend/components';
import {
  AnnotationController,
  CanvasController,
  MachineController,
  useSettingsStore,
  useUIStore,
} from '@assemblio/frontend/stores';
import { Canvas, RootState } from '@react-three/fiber';
import { useState } from 'react';
import { PCFSoftShadowMap } from 'three';
import { Annotations } from './Annotations';
import { AspectContainer } from './AspectContainer';
import { CanvasElements } from './CanvasElements';

interface Props {
  onCreated?(rootState: RootState): void;
}

export const Viewport = (props: Props) => {
  const [rootState, setRootState] = useState<RootState | undefined>(undefined);

  const ratio = useUIStore((state) => state.previewRatio);
  const background = useSettingsStore((state) => state.viewport.color);
  const isEditor = useUIStore((state) => state.view === 'editor');

  /* This div is neccessary, because the canvas grows in size indefinitely without it.
            This is a known bug: https://github.com/pmndrs/react-three-fiber/issues/1684 */
  return (
    <div
      style={{
        background: background,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 0,
      }}
    >
      <AspectContainer ratio={ratio} rootState={rootState}>
        <Canvas
          onPointerMissed={() => {
            MachineController.deselect();
          }}
          onPointerDown={() => {
            AnnotationController.stopEditing();
          }}
          shadows={{ type: PCFSoftShadowMap }}
          onCreated={(state: RootState) => {
            state.camera.layers.enableAll();
            CanvasController.setCamera(state.camera);
            CanvasController.setScene(state.scene);
            state.setEvents({
              filter: (intersections) => intersections.filter((i) => i.object.visible),
            });

            // We only select objects on layer 0
            state.raycaster.layers.set(0);
            props.onCreated && props.onCreated(state);
            setRootState(state);
          }}
          orthographic
          camera={{ manual: true }}
          style={{
            background: background,
            position: 'absolute',
            width: '100%',
            height: '100%',
            float: 'left',
          }}
        >
          <CanvasElements />
        </Canvas>
        {isEditor && <ContextMenuHandler />}
        <Annotations rootState={rootState} />
      </AspectContainer>
    </div>
  );
};
